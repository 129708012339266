body {
  margin: 0;
  font-family: Inter var,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y:auto!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.activeAdmin {
  color: rgb(0, 82, 204);
}

.adminDivider {
  margin-top: 17px;
  padding-top: 18px;
  border-top: 1px solid rgb(193, 199, 208);
}

.maxCardHeader {
  max-width: 9rem;
  overflow: hidden;
  word-wrap: break-word;
}

.columnHeaderText {
  text-transform: uppercase;
  color: rgb(94, 108, 132);
  font-size: 12.5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 13px 10px 0px;
  display: block;
}

.react-kanban-board {
  padding: 5px 5px 5px 0px;
}
.react-kanban-card {
  border-radius: 3px;
  background-color: #fff;
  padding: 4px;
  margin-bottom: 3px;
  width: fit-content;
}
.react-kanban-card-skeleton,
.react-kanban-card,
.react-kanban-card-adder-form {
  box-sizing: border-box;
  max-width: 250px;
  min-width: 250px;
}
.react-kanban-card--dragging {
  box-shadow: 2px 2px grey;
}
.react-kanban-card__description {
  padding-top: 10px;
}
.react-kanban-card__title {
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.react-kanban-column {
  padding: 1px;
  border-radius: 3px;
  background: rgb(244, 245, 247);
  margin: 5px 10px 5px 0px;
}
.react-kanban-column input:focus {
  outline: none;
}
.react-kanban-card-adder-form {
  border-radius: 3px;
  background-color: #fff;
  padding: 10px;
  margin-bottom: 7px;
}
.react-kanban-card-adder-form input {
  border: 0px;
  font-family: inherit;
  font-size: inherit;
}
.react-kanban-card-adder-button {
  width: 100%;
  margin-top: 5px;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid #ccc;
  transition: 0.3s;
  border-radius: 3px;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}
.react-kanban-card-adder-button:hover {
  background-color: #ccc;
}
.react-kanban-card-adder-form__title {
  font-weight: bold;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px;
}
.react-kanban-card-adder-form__title:focus {
  outline: none;
}
.react-kanban-card-adder-form__description {
  width: 100%;
  margin-top: 10px;
}
.react-kanban-card-adder-form__description:focus {
  outline: none;
}
.react-kanban-card-adder-form__button {
  background-color: #eee;
  border: none;
  padding: 5px;
  width: 45%;
  margin-top: 5px;
  border-radius: 3px;
}
.react-kanban-card-adder-form__button:hover {
  transition: 0.3s;
  cursor: pointer;
  background-color: #ccc;
}
.react-kanban-column-header {
  padding-bottom: 20px;
  font-weight: bold;
}
.react-kanban-column-header input:focus {
  outline: none;
}
.react-kanban-column-header__button {
  color: #333333;
  background-color: #ffffff;
  border-color: #cccccc;
}
.react-kanban-column-header__button:hover,
.react-kanban-column-header__button:focus,
.react-kanban-column-header__button:active {
  background-color: #e6e6e6;
}
.react-kanban-column-adder-button {
  border: 2px dashed #eee;
  height: 132px;
  margin: 5px;
}
.react-kanban-column-adder-button:hover {
  cursor: pointer;
}

.videoWrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
.sidebar-bg {
  background-color: #061636;
}

.bg-sidebar-active {
  background-color: #1f2a4c;
}